'use client'

import 'blocks/blocks.global.scss'
import 'layout/globals.scss'

import * as Sentry from '@sentry/nextjs'

import {Home} from 'assets/icons'
import {useEffect} from 'react'

export * as Home from 'assets/icons/home.svg'

export default function GlobalError({
	error
}: {
	error: Error & {digest?: string}
}) {
	useEffect(() => {
		Sentry.captureException(error)
	}, [error])

	return (
		<html>
			<body>
				<div
					style={{
						minHeight: '100vh',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}}
				>
					<p style={{textAlign: 'center'}}>
						<h1>500 - Error: Something went wrong</h1>
						<a
							href="/"
							style={{
								display: 'inline-block',
								marginTop: '30px',
								padding: '15px',
								borderRadius: '10px',
								backgroundColor: '#3f97bd'
							}}
						>
							<Home.default
								style={{
									fontSize: '20px',
									height: '1.25em',
									lineHeight: '1',
									display: 'block',
									color: '#f0f0f0'
								}}
							/>
						</a>
					</p>
				</div>
			</body>
		</html>
	)
}
